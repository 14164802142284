import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Portfolio from "../components/Portfolio";
import PortfolioDeckDownloadForm from "../components/forms/PortfolioDeckDownloadForm";
import ContactForm from "../components/forms/contactForm";

import EarIcon from "./../images/svg/ear.svg";
import PlanningIcon from "./../images/svg/planning.svg";
import ProgrammingIcon from "./../images/svg/programming.svg";
import LaunchIcon from "./../images/svg/launch.svg";

library.add(fas);

const IndexPage = () => (
  <Layout>
    <Seo
      lang="en"
      title="Freelance Web Developer - Web Designer | Yohan Dalpatadu"
    />
    <div className="bg-black h-screen">
      <div className="text-center h-full flex flex-wrap content-center justify-center">
        <div>
          <p className="text-white text-2xl lg:text-4xl">a</p>
          <h1 className="text-white">Freelance Web Developer</h1>
          <ul className="list-none">
            <li className="inline-block align-middle pr-3">
              <p className="text-white text-2xl lg:text-4xl mb-0">
                based in Sri Lanka
              </p>
            </li>
            <li className="inline-block align-middle">
              <StaticImage
                src="../images/sri-lanka.png"
                width={48}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Sri Lanka Flag"
              />
            </li>
          </ul>
          <div className="py-16">
            <a
              href="#download"
              className="focus:outline-none sm:text-xl font-bold text-gray-300 py-2.5 px-5 rounded-md border border-gray-300 hover:bg-gray-50 hover:text-black"
            >
              download my portfolio deck
            </a>
          </div>
          <div className="absolute bottom-10 right-0 left-0 text-center">
            <a href="#meet-yd" aria-label="Meet Yohan">
              <span>
                <FontAwesomeIcon
                  className="fill-current text-white animate-bounce"
                  icon={["fas", "chevron-down"]}
                  size="2x"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="meet-yd" className="container mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-20 pt-20 pb-5">
        <div className="lg:col-span-6 flex lex-wrap justify-center lg:block xl:flex xl:flex-wrap xl:justify-center">
          <StaticImage
            src="../images/yohan.png"
            width={500}
            quality={80}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Yohan's picture"
            className="object-contain rounded-full"
          />
        </div>
        <div className="lg:col-span-6">
          <h2> meet Yohan Dalpatadu </h2>
          <p className="heading-subtext mb-10">
            Hi, I am specialised in responsive designs and your website will be
            designed according to the standards and search engine friendly
            because I know that a fabulous website is the starting point for
            your online success.
          </p>
          <h3>my tech stack</h3>
          <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4 py-5">
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Figma.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Figma"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Bootstrap.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Bootstrap"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Bulma.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Bulma"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Sass.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Sass"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Tailwind-CSS.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tailwind CSS"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-JavaScript.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="JavaScript"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-React.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="React"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-NextJS.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="NextJS"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Gatsby.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Gatsby"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Contentful.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Contentful"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Strapi.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Strapi"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-PHP.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="PHP"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-MySQL.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="MySQL"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-WordPress.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="WordPress"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
            <div>
              <StaticImage
                src="../images/logos/tech-stack-Laravel.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Laravel"
                className="object-contain w-full rounded-lg transition ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 pt-10 xl:pt-20 pb-5">
        <div className="lg:col-span-6 xl:col-span-5">
          <h2>
            <span className="heading-start">modern digital solutions to </span>
            <span className="heading-between">grow your business</span>
          </h2>
          <p className="heading-subtext">
            Whether you want to redesign a website or create something entirely
            new, I am excited to help you out either way.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-20 py-7">
        <div className="lg:col-span-6 flex items-center">
        <StaticImage
            src="../images/a-user-checking-a-website.jpg"
            width={500}
            quality={50}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A user checking a website"
            className="object-contain w-full rounded-lg"
          />
        </div>

        <div className="lg:col-span-6 flex items-center">
          <div>
            <p>
              Close to 3 billion people use the internet every day, and records
              show that most of them, in the past year, have either shopped
              online or have reached out to a business over the web. There is
              plenty of opportunity here with a global reach of potential
              customers waiting to make use of your product offering or service.
            </p>
            <p>
              Many businesses are shifting from retail to online, and the key
              cornerstone in their success is a professionally-looking website
              that is both optimised for conversions and sleek in design. The
              core role of a web developer is to organise information, structure
              and create page layouts while communicating the key business
              selling points on a website. The end goal is to build a medium for
              the business to expand into, via a website which enables the
              business to attract new customers, showcase its services products,
              and to do business globally.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-20 py-7">
        <div className="lg:col-span-6 flex items-center order-1 lg:order-0">
          <div>
            <p>
              As a web developer, I strive to design &amp; develop the best
              websites possible. I specialise in responsive web design, and my
              goal is to design your website according to industry standards
              while being fully search engine optimised because I believe that a
              responsive, SEO first website is the starting point for your
              online success.
            </p>
            <p>
              Many webmasters use marketing methods and techniques that attract
              visitors to your website. But the primary purpose of a website is
              not just to draw traffic, but to transform these visitors to
              potential customers also known as a conversion.
            </p>
          </div>
        </div>

        <div className="lg:col-span-6 flex items-center order-0 lg:order-1">
        <StaticImage
            src="../images/a-laptop-with-a-coding-window-open-in-a-studio-room.jpg"
            width={500}
            quality={50}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A laptop with a coding window open in a studio room"
            className="object-contain w-full rounded-lg"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-20 py-7">
        <div className="lg:col-span-6 flex items-center">
        <StaticImage
            src="../images/a-plant-with-coins.jpg"
            width={500}
            quality={50}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A plant with coins"
            className="object-contain w-full rounded-lg"
          />
        </div>

        <div className="lg:col-span-6 flex items-center">
          <div>
            <p>
              A subpar website can cause you to waste your money on alternative
              marketing strategies that do not convert. A point to consider is
              that, regardless of how good your marketing strategy is, if your
              website fails to capture the audience’s eye, then you’re not going
              to convert.
            </p>
            <p>
              I provide responsive web development &amp; SEO services for you to
              get you the best ROI from your website and help your business
              grow. I custom design websites to suit your requirements, from
              basic to advanced models in terms of design and functionality.
            </p>
            <p>
              I want your business to succeed and will do everything possible to
              help you get the best ROI from your website. Schedule an
              appointment with me to discuss your requirements and I’ll be happy
              to talk through your project.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 pt-10 xl:pt-20 pb-5">
        <div className="lg:col-span-6 xl:col-span-5">
          <h2>
            <span className="heading-start">the </span>
            <span className="heading-between">process</span>
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-20 py-7">
        <div className="lg:col-span-3">
          <div className="lg:p-2 xl:p-10 rounded-lg">
            <EarIcon className="object-fill mx-auto w-24 h-24" />
            <div className="text-center mt-10">
              <h3>Listen</h3>
              <p>I take time to understand your requirement.</p>
            </div>
          </div>
        </div>

        <div className="lg:col-span-3 process-card">
          <div className="lg:p-2 xl:p-10 rounded-lg">
            <PlanningIcon className="object-fill mx-auto w-24 h-24" />
            <div className="text-center mt-10">
              <h3>Plan</h3>
              <p>
                I plan and design a solution with the best technologies that
                meet the objectives.
              </p>
            </div>
          </div>
        </div>

        <div className="lg:col-span-3 process-card">
          <div className="lg:p-2 xl:p-10 rounded-lg">
            <ProgrammingIcon className="object-fill mx-auto w-24 h-24" />
            <div className="text-center mt-10">
              <h3>Develop</h3>
              <p>Brings the solution design to life.</p>
            </div>
          </div>
        </div>

        <div className="lg:col-span-3 process-card">
          <div className="lg:p-2 xl:p-10 rounded-lg">
            <LaunchIcon className="object-fill mx-auto w-24 h-24" />
            <div className="text-center mt-10">
              <h3>Launch</h3>
              <p>
                On time and my services include post-launch maintenance to
                ensure everything is working smoothly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 pt-10 xl:pt-0 pb-5">
        <div className="lg:col-span-6 xl:col-span-5">
          <h2>
            <span className="heading-start">what I have </span>
            <span className="heading-between">completed</span>
            <span className="heading-end">so far </span>
          </h2>
        </div>
      </div>
    </div>

    <div className="pb-20">
      <Portfolio />
    </div>

    <div id="download" className="bg-black">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 py-20">
          <div className="lg:col-start-4 lg:col-span-6 text-center">
            <h2>
              <span className="heading-start text-white">you'll love </span>
              <span className="heading-between text-white">
                working with me
              </span>
            </h2>
            <p className="heading-subtext text-gray-400">
              I've been crafting beautiful websites, launching stunning brands
              and making clients happy for years with my craftsmanship,
              remarkable client care and passion for design.
            </p>
            <PortfolioDeckDownloadForm />
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 pt-20 pb-5">
        <div className="lg:col-span-6 xl:col-span-5">
          <h2>
            <span className="heading-start">let's </span>
            <span className="heading-between">make it happen</span>
          </h2>
          <p className="heading-subtext">
            I would love to work with you so why are you waiting for. Drop me a
            line and let’s start working together! Please fill out the form
            below to get in touch with me or request for a quote here. I’ll
            promise to get back to you shortly.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-20 py-7">
        <div className="lg:col-span-6">
          <ContactForm />
          <hr className="mt-10 mb-5"/>
          <h4>Other Contact Details</h4>
          <ul className="space-y-3">
            <li>
              <span className="sr-only">Email</span>
              <FontAwesomeIcon
                icon={["fas", "envelope"]}
                size="lg"
                className="mr-2"
              />{" "}
              <a href="mailto:&#121;&#100;&#64;&#121;&#111;&#104;&#97;&#110;&#46;&#100;&#101;&#118;">
                yd@yohan.dev
              </a>
            </li>
            <li>
              <span className="sr-only">WhatsApp</span>
              <FontAwesomeIcon
                icon={["fab", "whatsapp"]}
                size="lg"
                className="mr-2"
              />{" "}
              <a href="whatsapp:+94713727272">+94 71 372 7272</a>
            </li>
          </ul>
        </div>
        <div className="lg:col-span-6">
          <h3>Got Questions?</h3>
          <p className="heading-subtext">
            Here are just a few of the common questions I’ve been asked.
          </p>
          <div className="tabs">
            <div className="tab">
              <input type="checkbox" className="accordion" id="check1" />
              <label className="tab-label" htmlFor="check1">
                How long will take my site to be designed?
              </label>
              <div className="tab-content">
                Depends. Each site is unique and has specific requirements. I
                will give you an approximate delivery timeline along with my
                quote. If you do have a deadline fear not, I will work my
                hardest to meet it.
              </div>
            </div>
            <div className="tab">
              <input type="checkbox" className="accordion" id="check2" />
              <label className="tab-label" htmlFor="check2">
                How much will it cost to build a website?
              </label>
              <div className="tab-content">
                The cost of a website depends on its complexity. I would be
                happy to discuss your requirements over email or via my contact
                form and I shall provide you with a quote. I am looking forward
                to working with you.
              </div>
            </div>
            <div className="tab">
              <input type="checkbox" className="accordion" id="check3" />
              <label className="tab-label" htmlFor="check3">
                Once the website is launched, what after sales services do you
                offer?
              </label>
              <div className="tab-content">
                I take my customer support very seriously, and upon website
                completion, I will draft a maintenance agreement to your liking
                with your consent.
              </div>
            </div>
            <div className="tab">
              <input type="checkbox" className="accordion" id="check4" />
              <label className="tab-label" htmlFor="check4">
                Will my site be search engine optimised?
              </label>
              <div className="tab-content">
                All the websites are created with SEO in mind, with complete
                compliance of search engine guidelines. However, I can offer you
                more robust, on-page off-page optimisation services based on
                your site requirement.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
